<!--企业注册页面  -->
<template>
  <div class="wrap">
    <form-cpt :model="formData" :rule="rules" ref="baseForm">
      <form-item label="公司名称" prop="license_company_name">
        <input
          class="ipt"
          type="text"
          v-model.trim="formData.license_company_name"
        />
      </form-item>
      <form-item label="联系人员" prop="contact_name">
        <input class="ipt" type="text" v-model.trim="formData.contact_name" />
      </form-item>
      <form-item label="手机号码" prop="username">
        <input class="ipt" type="text" v-model.number="formData.username" />
      </form-item>
      <form-item label="验证码" prop="code">
        <input class="ipt-code" type="text" v-model.trim="formData.code" />
        <button class="send-code sending" v-if="sending">
          重新发送{{ sendTime }}秒
        </button>

        <button class="send-code" @click="sendCode" v-else>发送验证码</button>
      </form-item>

      <form-item label="设置密码" prop="password">
        <input class="ipt" type="password" v-model.trim="formData.password" />
      </form-item>
      <form-item label="确认密码">
        <input class="ipt" type="password" v-model.trim="confirm_password" />
      </form-item>
      <form-item label="城市选择">
        <el-cascader
          v-model="city"
          :options="optionsCity"
          @change="handleChange"
        ></el-cascader>
        <!--@change="handleChange"  -->
      </form-item>

      <label class="form-item_label">营业执照：</label>
      <el-upload
        class="upload"
        :class="{ disable: imagesMax }"
        :on-success="successFile"
        :file-list="fileList"
        :on-remove="OnremoveHandler"
        action="/api/kapin_ee/user/v1/files_upload/"
        list-type="picture-card"
        :on-preview="handlePictureCardPreview"
      >
        <i class="el-icon-plus"></i>
      </el-upload>
      <el-dialog :visible.sync="dialogVisible">
        <img width="100%" :src="dialogImageUrl" alt />
      </el-dialog>

      <div class="register" @click="submit">注册</div>
      <!-- <div class="register" @click="$router.replace('/login')">退出</div> -->
    </form-cpt>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import formCpt from "./../../../components/Form/form";
import axios from "axios";
import formItem from "./../../../components/Form/formItem";
import { register, sendCodeApi, isTrueCodeApi, check_code } from "@/api/login";
import { showCity } from "@/api/me/index";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { formItem, formCpt },
  data() {
    //这里存放数据
    return {
      fileList: [],
      city: "",
      optionsCity: [],
      imagesMax: false,
      noproblemArr: [],
      submitStatus: false,
      isFinish: 0,
      //图片路径
      dialogImageUrl: "",
      //是否显示el-dialog
      dialogVisible: false,
      //全部数据
      formData: {
        //公司名称
        license_company_name: "",
        //联系人员
        contact_name: "",

        //验证码
        code: "",
        //帐号
        username: "",
        //密码
        password: "",
        license: "",
      },
      //确认密码
      confirm_password: "",

      rules: {
        license_company_name: [
          { required: true, message: "公司名称不能为空", trigger: "blur" },
        ],
        contact_name: [
          { required: true, message: "联系人员不能为空", trigger: "blur" },
        ],

        code: [{ required: true, message: "验证码不能为空", trigger: "blur" }],
        username: [
          { type: "number", message: "手机号应该为数字值" },

          {
            required: true,
            message: "手机号码不能为空",
            trigger: "blur",
            type: "number",
          },
          // pattern: /^0{0,1}(13[0-9]|15[7-9]|153|156|18[7-9]|17[0-9])[0-9]{8}$/,
          {
            pattern: /^1{1}[3456789]{1}[0-9]{9}$/,
            message: "手机号格式不对",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "密码不能为空", trigger: "blur" },
          {
            min: 6,
            max: 16,
            message: "长度在 6 到 16 个字符",
            trigger: "blur",
          },
        ],
      },
      //验证码发送中
      sending: false,
      sendTime: 60,
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {
    // formData: {
    //   handler: function(val, oldval) {
    //     console.log(val)
    //   },
    //   deep: true //对象内部的属性监听，也叫深度监听
    // }
  },
  //方法集合
  methods: {
    //文件列表移除文件时的钩子
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    //点击文件列表中已上传的文件时的钩子
    handlePictureCardPreview(file) {
      console.log(file);
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    //发送验证码
    sendCode() {
      if (!/^1{1}[3456789]{1}[0-9]{9}$/.test(this.formData.username)) {
        this.$message({
          message: "请输入手机号",
          type: "warning",
        });
      } else {
        this.sending = true;
        this.sendTime = 60;
        let a = setInterval(() => {
          if (this.sendTime === 0) {
            this.sending = false;
            clearInterval(a);
            a = null;

            return;
          }
          console.log(a);
          this.sendTime--;
          console.log(this.sendTime);
        }, 1000);

        sendCodeApi({ phone: this.formData.username }).then((res) => {
          console.log(res);
        });
      }
    },
    //注册
    submit() {
      console.log(this.city);
      console.log(this.confirm_password);
      if (this.confirm_password === "") {
        this.$message({
          message: "不能为空",
          type: "warning",
        });
        return;
      }

      if (this.formData.password != this.confirm_password) {
        this.$message({
          message: "两次密码不一致",
          type: "warning",
        });
        return;
      }

      console.log(this.formData);
      this.$refs.baseForm
        .validate()
        .then((res) => {
          if (res) {
            console.log(this.city);
            if (Array.isArray(this.city)) {
              this.formData.province_id = Number(this.city[0]);
              this.formData.city_id = Number(this.city[1]);
              this.formData.district_id = Number(this.city[2]);
            } else {
              this.$message({
                message: "请选择城市",
                type: "warning",
              });
              return;
            }
            if (this.formData.license === "") {
              this.$message({
                message: "请上传营业执照",
                type: "warning",
              });
              return;
            }
            check_code({
              phone: this.formData.username,
              code: this.formData.code,
            }).then((res) => {
              console.log(res);
              if (res.code == 0) {
                register(this.formData).then((res) => {
                  if (res.code === 0) {
                    this.$message({
                      message: res.msg,
                      type: "success",
                    });
                    this.$router.go(-1);
                  } else {
                    this.$message({
                      message: res.msg,
                      type: "error",
                    });
                  }
                });
              } else {
                this.$message.warning(res.msg);
              }
            });
          }
        })
        .catch((e) => {
          // console.log(e)
        });
    },
    //城市选择
    // handleChange(value) {
    //   console.log(value)
    // },
    //删除图片
    OnremoveHandler(file, fileList) {
      this.formData.license = "";
      if (fileList.length >= 1) {
        this.imagesMax = true;
      } else {
        this.imagesMax = false;
      }
    },
    //上传图片
    successFile(response, file, fileList) {
      console.log(response);

      console.log(response);
      this.formData.license = response.file_id;
      console.log(this.formData.license);
      if (fileList.length >= 1) {
        this.imagesMax = true;
      } else {
        this.imagesMax = false;
      }
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    console.log("获取地区");
    showCity({}).then((res) => {
      console.log("sssssssssss");
      console.log(res);
      // this.optionsCity = [{ label: "唐山市", value: "130200", children: [] }];

      this.optionsCity = res.data;

      // res.data[0].children.map((item, index) => {
      //   if (index != 0) {
      //     this.optionsCity[0].children.push(item);
      //   }
      // });
    });
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style scoped lang="scss">
/* //@import url(); 引入公共css类 */
/* input style */
.wrap {
  padding: 80px 0 0 0;
  /* width: 100%; */
  margin: 100px auto 0;
  width: 1000px;
  box-sizing: content-box;

  border-right: 1px solid #ccc;
  /* border-left: 1px solid #ccc; */

  /* background: linear-gradient(
    45deg,
    rgb(255, 100, 38) 1%,
    rgb(255, 255, 255) 40%
  ); */
}

.register {
  width: 100px;
  height: 40px;
  background-color: $main2_color;
  color: #fff;
  text-align: center;
  line-height: 40px;
  border-radius: 5px;
  margin-left: 70%;
  float: right;
  /* margin-right: ; */

  margin-top: 50px;
  cursor: pointer;
}

/deep/.el-upload--picture-card:hover,
.el-upload:focus {
  border: 1px dashed $main2_color;
}

.form-item_label {
  display: inline-block;
  width: 150px;
  margin-left: 30%;

  text-align: right;
}
.upload {
  margin-left: calc(30% + 150px);
}
.ipt {
  display: inline-block;
  height: 40px;
  width: 250px;
  box-sizing: border-box;

  box-shadow: 0 24px 64px 0 rgba(14, 20, 33, 0.1);
  border-radius: 4px;
  /* text-indent: 2rem; */
  padding: 0 30px 0 15px;
  font-size: 17px;
  color: #666;
}
.ipt-code {
  display: inline-block;
  height: 40px;
  width: 150px;

  box-shadow: 0 24px 64px 0 rgba(14, 20, 33, 0.1);
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;

  /* text-indent: 2rem; */
  padding: 0 30px 0 15px;
  font-size: 17px;
  color: #666;
  vertical-align: middle;
  box-sizing: border-box;
  /* border: 1px solid red; */
}
.send-code {
  color: #fff;
  box-sizing: border-box;
  vertical-align: middle;
  display: inline-block;
  /* border: 1px solid red; */
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 40px;
  width: 100px;
  background-color: $main2_color;

  cursor: pointer;
}
.send-code.sending {
  background-color: #ccc;
  color: $main2_color;
}
.disable /deep/ .el-upload--picture-card {
  display: none;
}
div /deep/ .el-cascader .el-input {
  width: 250px;
}
</style>
